.custom-table {
  display: flex;
  flex-direction: column;
}

.custom-table-header,
.custom-table-row {
  display: flex;
}

.custom-table-cell {
  flex: 1;
  border: 1px solid #ddd;
  padding: 8px;
  background-color: white;
}

.custom-table-header-cell {
  background-color: #f2f2f2;
  font-weight: bold;
}

.custom-table-cell.warning {
  background-color: #ffeb3b; /* Yellow background */
}

.custom-table-cell.error {
  background-color: #f44336; /* Red background */
  color: white;
}

.custom-table-cell.success {
  background-color: rgb(157, 212, 157); /* Green background */
  color: white;
}
