body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: slateblue; */
  background-color: white;
  /* width: 100vw; */
  overflow-x: hidden;
}

/* html {
  width: 100vw;
  overflow-x: hidden;
} */

.scrolling-tabs {
  overflow-x: hidden;
  flex-wrap: nowrap;
  overflow-y: hidden;
}

@media (max-width: 575px) {
  .scrolling-tabs {
    overflow-x: scroll;
  }
}

a {
  text-decoration: none;
}
.nav-tabs .nav-link {
  border-radius: 0%;
}

/* a {
  text-decoration: none;
  color: rgba(255,255,255,.5);
}

a:hover {
  text-decoration: none;
  color: rgba(255,255,255,1);
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#activeNav {
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
}

.tab {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}

body::-webkit-scrollbar {
  width: 0.25em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.chart-container {
  position: relative;
  height: 300px;
  min-width: 100px;
  max-width: 400px;
}
